import { faCheckCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import axios from 'axios'
function Modal({closeFn=()=>null, fill,title}){
    var [name, setName] = useState('')
    var [email, setEmail] = useState('')
    var [phone, setPhone] = useState('')
    var [tuay, setTuay] = useState('')
    var [success, setSuccess] = useState(false)
    var location = window.location.href
    const sendEmail = (e)=>{
        e.preventDefault()
        var url = process.env.NODE_ENV == 'production' ? 'https://api.merchantreferral.partners/sendmail':'http://api.mysite.localhost:8081/sendmail'
        axios.post(url,{name, email,phone,tuay,location},
        {headers:{
                'Authorization': ``
        }
        }).then(res=>setSuccess(true)).catch(e=>console.log(e))
    }
    return(
        <div>
            <div className="modal z-50 h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-20 px-5 overflow-y-auto">
                {!success?
                <div className="bg-white rounded shadow-lg lg:w-1/2 w-full">
                    {/* <div className='w-1/3 hidden lg:inline-block cw-bg-lightblue py-10 px-5'>
                        <FontAwesomeIcon className="text-white text-4xl" icon={faEnvelope}/>
                    </div> */}
                    <div className="py-10 px-5">
                        {/* <button onClick={closeFn}><img src={FaTimesBlack}/></button> */}
                    <form action='/' method="POST" enctype="multipart/form-data" onSubmit={sendEmail}>
                        <h3 className="font-semibold text-2xl pb-5">{title?title:'Contact Form'}</h3>
                        <div id="referralForm" className="grid grid-cols-2 gap-3">
                            <div className="flex flex-col col-span-2">
                            <label className="font-medium text-sm text-gray-900" for="name">Name</label>
                            <input onChange={(e)=>setName(e.target.value)} required autocomplete="nah" type="text" name="name" id="name" placeholder="e.g. John Appleseed"
                                className=" w-100 mt-2 py-2 px-3 rounded-lg  border  bg-gray-100 focus:bg-white  text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"/>
                            </div>
                            <div className="flex flex-col col-span-2">
                            <label className="font-medium text-sm text-gray-900" for="email">Email</label>
                            <input onChange={(e)=>setEmail(e.target.value)} required autocomplete="nah" type="text"  name="email" id="email" placeholder=""
                                className=" w-100 mt-2 py-2 px-3 rounded-lg  border  bg-gray-100 focus:bg-white  text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"/>
                            </div>
                            <div className="flex flex-col col-span-2">
                            <label className="font-medium text-sm text-gray-900" for="phone">Phone</label>
                            <input onChange={(e)=>setPhone(e.target.value)} required autocomplete="nah" type="text" name="phone" id="phone" placeholder="e.g. (215) 555 5555"
                                className=" w-100 mt-2 py-2 px-3 rounded-lg  border  bg-gray-100 focus:bg-white  text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"/>
                            </div>
                            <div className="flex flex-col col-span-2">
                            <label className="font-medium text-sm text-gray-900" for="company">Tell us about yourself</label>
                            <textarea onChange={(e)=>setTuay(e.target.value)} required autocomplete="nah" type="text" name="tuay" id="tuay" placeholder={fill ? `I want to be a CWA ${fill} because...`:null} rows={5}
                                className=" w-100 mt-2 py-2 px-3 rounded-lg  border  bg-gray-100 focus:bg-white  text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none">
                            </textarea>
                            </div>
                            <div className="col-span-2 flex justify-start space-x-3">
                                <button id="submit-button" type=''
                                    className="flex cw-bg-lightblue px-3 py-2 mt-4 rounded text-white font-semibold">
                                    <span id="submit-text">Submit Form</span>
                                </button>
                                <a id="" type="" onClick={closeFn}
                                    className="cursor-pointer flex bg-white shadow-sm font-medium border text-gray-600 hover:bg-gray-100 px-3 py-2 mt-4 rounded font-semibold">
                                    <span id="submit-text">Close</span>
                                </a>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
                :
                <div class="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow bg-white ">
                <div class="">
                    <div class="text-center p-5 flex-auto justify-center">
                        <FontAwesomeIcon className="text-6xl flex items-center mx-auto text-green-500" icon={faCheckCircle}/>
                        <h2 class="text-xl font-bold pt-3 pb-4 ">Your Form Has Been Sent!</h2>
                        <p class="text-sm text-gray-500 px-8">Your form has been successfully submitted, <u>you will receive a copy of
                            this form to your email.</u></p>
                    </div>
                    <div class="p-3  mt-2 text-center space-x-4 md:block">
                        <a onClick={closeFn}
                        class="cursor-pointer mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-lg hover:bg-gray-100">
                        Close
                        </a>
                    </div>
                </div>
                </div>
                }
                
            </div>
        </div>
    )
}
export default Modal;