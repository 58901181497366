import React, { useState } from 'react';
import Layout from '../../../components/Layout';
import Merchants_Navbar from '../../../components/Merchants_Navbar';
import Modal from '../../../components/Modal';
import '../../../index.css'
function Index(props) {
    const [modalOpen, setModal] = useState(false)
    const closeModal = () => {
        setModal(false)
    }
    return (
        <div>
            <Layout location={'Merchants'} title={'Gateways'}>
            {modalOpen ? <Modal title="Request a Quote" fill="Merchant" closeFn={closeModal} />:null}
            <Merchants_Navbar location={'gateways'}/>
            <section className="cw-bg-bluegradient text-center py-10 px-5">
                <div className="text-center w-100">
                    <h1 className="text-5xl text-white font-bold pb-2">Gateways</h1>
                </div>
            </section>
            <section className='py-20 bg-gray-100'>
                <div className="max-w-3xl mx-auto px-5 text-center">
                    <div className='mb-7 space-y-5'>
                        <h2 className="text-3xl cw-text-lightblue font-bold mb-3">Payment Gateways: Process Credit Cards Online!</h2>
                        <p className="font-serif text-xl leading-8 text-left">
                            A payment gateway is an e-commerce application service provider service that authorizes credit card payments for 
                            e-businesses, online retailers, bricks and clicks, or traditional brick and mortar. It is the equivalent of a physical 
                            point of sale terminal located in most retail outlets.
                        </p>
                        <p className="font-serif text-xl leading-8 text-left">
                            Below you will find a list of the Gateways that we currently process through for our merchants.
                        </p>
                        <p className="font-serif text-xl leading-8 text-left">
                            Don't See your current Gateway on this list? Let us know and we will work with your provider to 
                            make sure that we can accommodate your system!
                        </p>
                    </div>
                </div>
            </section>
            <div>
                <section className="py-10 px-5">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650549859/cwa/assets/images/Merchants/Gateways/authorizenet_i2jxoo.jpg"/>
                            </div>
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">AUTHORIZE.NET</h2>
                                <p className="py-4 font-serif">
                                Connecting a website to the payment processing networks is exceptionally difficult and typically beyond the expertise and technical resources of most online merchants. Instead, merchants can easily connect to the Authorize.Net Payment Gateway, which provides the complex infrastructure and security necessary to ensure fast, reliable and secure transmission of transaction data. Authorize.Net manages the routing of transactions just like a traditional credit card swipe machine you find in the physical retail world, however, Authorize.Net uses the Internet instead of a phone line.
                                </p>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5 bg-gray-100">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">BRAINTREE</h2>
                                <p className="py-4 font-serif">
                                We provide the tools people need to build businesses. We make it possible for companies all over the world to accept payments online and let customers make a purchase with one touch. So an engineer in London can launch his latest mobile venture in Sydney. And a student in New York can pay her rent. We build the technology. You make it meaningful with your ideas and innovations.
                                </p>
                            </div>
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650549859/cwa/assets/images/Merchants/Gateways/braintree11_tb1krc.png"/>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650549859/cwa/assets/images/Merchants/Gateways/BRIDGEPAY_ntska6.jpg"/>
                            </div>
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">BRIDGEPAY</h2>
                                <p className="py-4 font-serif">
                                BridgePay Network Solutions, LLC (BridgePay) is a transaction gateway company specializing in providing turnkey payment application solutions. BridgePay's broad scope of products offers solutions for card present, mobile commerce and ecommerce environments. BridgePay's broad network of processing partners offers connectivity to over 30 domestic and international processors, 5 ACH providers, and 16 gift and loyalty companies. BridgePay supports multiple integration methods ranging from direct integration to its best in class self-running payment application, PayGuardian.
                                </p>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5 bg-gray-100">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">CYBERSOURCE</h2>
                                <p className="py-4 font-serif">
                                Born during the dawn of eCommerce in 1994, CyberSource was one of the pioneers in online payment and fraud management services for medium and large-sized merchants. In 2010 CyberSource was acquired by, and operates as a wholly-owned subsidiary of Visa Inc. Today, over 400,000 businesses worldwide use CyberSource solutions. The company is headquartered in Foster City, California, with offices throughout Asia, Europe, Latin America, the Middle East and Africa, and the United States.
                                </p>
                                <p className="py-4 font-serif">
                                    ShopKeep accepts mobile payments via a partnership with PayPal[2] and another partnership with LevelUp.
                                </p>
                            </div>
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650549860/cwa/assets/images/Merchants/Gateways/CYBERSOURCE_lprw1b.png"/>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650549860/cwa/assets/images/Merchants/Gateways/DATACAP_ifu5qc.jpg"/>
                            </div>
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">DATACAP NET EPAY</h2>
                                <p className="py-4 font-serif">
                                NETePay 5.0 is a family of Windows applications which are designed to process credit, debit and EBT transactions using the Internet for communications to a processing provider. Separate versions of NETePay are available for most North American processing providers that support Internet payments.
                                </p>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5 bg-gray-100">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">ELEMENT</h2>
                                <p className="py-4 font-serif">
                                Headquartered in Chandler, Arizona, Element Payment Services, Inc., a Vantiv company (NYSE: VNTV), is an industry leading software business that develops PCI DSS compliant technology designed to secure the processing, transmitting, and storing of payment card related data. Element's technology is deployed through partnerships with Point of Interaction (POI) hardware vendors, business management software providers and systems dealers.
                                </p>
                                <p className="py-4 font-serif">
                                    ShopKeep accepts mobile payments via a partnership with PayPal[2] and another partnership with LevelUp.
                                </p>
                            </div>
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650549860/cwa/assets/images/Merchants/Gateways/element_bivj2z.png"/>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650549860/cwa/assets/images/Merchants/Gateways/EPROCESSING_yqmqch.png"/>
                            </div>
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">EPROCESSING NETWORK</h2>
                                <p className="py-4 font-serif">
                                Our Java -Point-Of-Sale (POS), software for Windows® based PCs and laptops, which interacts with our cloud-based gateway, enables merchants to securely process credit, PIN Debit, signature capture, check, Inventory, Apple Pay and EMV transactions. With our simple user-interface and robust features, merchants can start accepting electronic payments faster and easier.
                                </p>
                                <ul className='py-4 font-serif'>
                                    <li>Sync transactions to QuickBooks®.</li>
                                    <li>Detailed and consolidated transaction batch reporting.</li>
                                    <li>Inventory management options.</li>
                                    <li>Multiple terminals and clerk management.</li>
                                    <li>Use existing computers, printers and internet access to process secure payments.</li>
                                    <li>Supports card and check readers, cash drawers, scanners, and PIN pad/signaturEcapture devices.</li>
                                </ul>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5 bg-gray-100">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">EZIC</h2>
                                <p className="py-4 font-serif">
                                In 1999, Ezic launched its Internet Payment Platform, a high-performance, highly scalable, value-added, next generation Gateway that supports online processing of credit cards and electronic check transactions. Our platform is easy to set up, easy to use, and fully capable of integrating with existing business applications through XML and EDI. Ezic has been completely funded by customer revenue since our second quarter of full operations.
                                </p>
                            </div>
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650549860/cwa/assets/images/Merchants/Gateways/EZIC_ni4rif.png"/>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650549860/cwa/assets/images/Merchants/Gateways/icverify_yiqsz1.png"/>
                            </div>
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">IC VERIFY</h2>
                                <p className="py-4 font-serif">
                                Whether used as a stand-alone software solution or integrated with another application, ICVERIFY Software can help you handle your transactions quickly and efficiently, regardless of their origin. You can use existing phone lines, dedicated circuits or Internet connections to process your payment transactions, and multiple users and merchants can be configured in a single installation.
                                </p>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5 bg-gray-100">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">NETBILLING</h2>
                                <p className="py-4 font-serif">
                                Since 1998, NETbilling, Inc. has provided merchant accounts, multi currency transaction processing, and call center services to thousands of internet merchants and companies of all sizes. NETbilling's flexible solutions process credit cards, debit cards, electronic checks, and telephone orders, and work with any business model, including internet, broadband, wireless, call centers and retail establishments of all types.
                                </p>
                                <p className="py-4 font-serif">
                                Services include advanced reporting tools, membership site management, fraud scrubbing, a free shopping cart, and 24/7 live service for merchants and their customers. NETbilling facilitates merchants every step of the way from merchant account acquisition to final implementation, training, and deployment.
                                </p>
                            </div>
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650549859/cwa/assets/images/Merchants/Gateways/netbilling_dbd3em.png"/>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650552810/cwa/assets/images/Merchants/Gateways/NMI-Logo-Purple_Text_mp1jrh.jpg"/>
                            </div>
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">NMI</h2>
                                <p className="py-4 font-serif">
                                Network Merchants, LLC (NMI) builds e-commerce payment gateways for companies that want to process online transactions in real-time anywhere in the world. With an exceptional team of software engineers, NMI created, maintains and delivers a substantial suite of payment solutions through a unique channel of distribution.
                                </p>
                                <p className="py-4 font-serif">
                                This channel is comprised of financial institutions, ISOs/MSPs, payment processors, VARs, and other e-commerce developers interested in retaining their existing merchant-customers and acquiring new business. With an easy to integrate transparent and private-label program, Affiliate Partners maintain their own look and feel while providing merchants with efficient and secure payment processing solutions.
                                </p>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5 bg-gray-100">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">/n SOFTWARE</h2>
                                <p className="py-4 font-serif">
                                For more than 20 years, software developers for nearly every Fortune 500 and Global 2000 company worldwide have used /n software products to build powerful connected applications. Our client list includes companies such as Microsoft, Sony, Xerox, Cisco, Dell, Motorola, Intel, and many more. All of our products are backed by an excellent record of accomplishment based on providing robust enterprise-class solutions backed by professional technical support.
                                </p>
                                <p className="py-4 font-serif">
                                Our flagship product IP*Works! is the best selling, most comprehensive suite of programmable Internet components worldwide. IP*Works! eliminates much of the complexity of developing connected applications, by providing easy-to-use programmable components that facilitate tasks such as sending email, transferring files, browsing the Web, consuming XML Web services, and much more.
                                </p>
                            </div>
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650551251/cwa/assets/images/Merchants/Gateways/Logo-nSoftware_ohjz1h.png"/>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650551251/cwa/assets/images/Merchants/Gateways/logo_orbital_sqqfuc.gif"/>
                            </div>
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">ORBITAL</h2>
                                <p className="py-4 font-serif">
                                Orbital® Payment Gateway enables online payment processing for the most basic storefronts or for highly integrated e-commerce systems. Merchants can establish secure online transaction processing in a true one-stop-shop environment. The Orbital suite of products is fully customizable – you can select the services to add to your Gateway account that best meet your business needs. You can deploy the interface to fully commerce-enable your storefronts. We provide all service, support, processing, technology, billing and customer care.
                                </p>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5 bg-gray-100">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">PAYPAL PAYFLOW PRO</h2>
                                <p className="py-4 font-serif">
                                A payment gateway links your website to your processing network and merchant account. Like most gateways, Payflow Payment Gateway handles all major credit and debit cards. What makes our gateway different is the low rates and incremental sales boost from offering PayPal and PayPal Credit payment options on your site.
                                </p>
                            </div>
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650551251/cwa/assets/images/Merchants/Gateways/PayPalPayflowPro_lgyjam.png"/>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650551251/cwa/assets/images/Merchants/Gateways/PayTraceSeal_3l_w0skyu.png"/>
                            </div>
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">PAYTRACE</h2>
                                <p className="py-4 font-serif">
                                PayTrace began as an idea. The idea that payment systems could be better. Merchants didn't have to settle for mediocre gateways offering little to no support. It was possible to delight merchants with 5-star serviceand a system that was comprehensive, lowered processing costs, andincreased security.
                                </p>
                                <p className="py-4 font-serif">
                                Today, we live and breathe this idea. Innovation drives improvements to our systems while our team is committed to serving merchants and partners. At PayTrace, we are proud to be advancing payments one relationship at a time.
                                </p>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5 bg-gray-100">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">PC CHARGE</h2>
                                <p className="py-4 font-serif">
                                Turn any PC or PC-based POS system into a secure payment terminal with Verifone PCCharge. PCCharge supports a wide range of peripherals, so it's easy to accept any form of payment. PC payment software is a cost-effective solution for businesses of all types including brick-and-mortar merchants, e-commerce, MOTO, and QSR/Restaurant.
                                </p>
                                <p className="py-4 font-serif">
                                Economical solution with no need for additional hardware, Securely accept payment, Easy to set up and use
                                </p>
                                <p className="py-4 font-serif">
                                PCCharge is an all-inclusive PC-based electronic payments solution – software that easily integrates to your system.
                                </p>
                            </div>
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650551251/cwa/assets/images/Merchants/Gateways/PCCharge_Logo_hknm23.jpg"/>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650551251/cwa/assets/images/Merchants/Gateways/PLUGNPAY_n23cly.jpg"/>
                            </div>
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">PLUG'N PAY</h2>
                                <p className="py-4 font-serif">
                                Plug'n Pay delivers the industry's easiest-to-integrate eCommerce gateway . . .plus a wide range of innovative, cost-effective solutions to maximize online and point-of-sale profits, reduce fraud and build customer loyalty.
                                </p>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5 bg-gray-100">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">ROCKETGATE</h2>
                                <p className="py-4 font-serif">
                                Behind RocketGate, stands a solid engineering team comprised of individuals with experience developing mission critical applications, including the communications infrastructure for NASA's Deep Space Network. Members of our team have developed aircraft and cargo tracking systems for the Military Airlift Command, live internet broadcasts such as the 2008 and 2010 Olympic Games, and have received numerous patents.
                                </p>
                                <p className="py-4 font-serif">
                                The RocketGate team has applied their experience and knowledge to the creation of RocketGate's secure, fault-tolerant and reliable payment processing platform, from initial development to continual responsiveness and support. Yes, it is rocket science!
                                </p>
                            </div>
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650551251/cwa/assets/images/Merchants/Gateways/logo_rocketgate_ydbe0s.png"/>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650551251/cwa/assets/images/Merchants/Gateways/Shopify-bag-and-logotype_ddwp3v.png"/>
                            </div>
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">SHOPIFY</h2>
                                <p className="py-4 font-serif">
                                Nearly a decade ago we started an online store to sell snowboard equipment directly to those who loved the sport as much as us.
                                </p>
                                <p className="py-4 font-serif">
                                We could have listed our products on a number of marketplaces, but we wanted to own our brand and build relationships with our customers, along with selling our goods. Such a tool didn't exist, so we built it for ourselves. We soon realized a number of other stores were in need of a hassle-free platform to build their retail business, and Shopify was born.
                                </p>
                                <p className="py-4 font-serif">
                                We focus on making commerce better for everyone, so businesses can focus on what they do best: building and selling their products. Today, merchants use our platform to manage every aspect of their business — from products to orders to customers, selling online, in retail stores, and on the go.
                                </p>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5 bg-gray-100">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">SHIFT4</h2>
                                <p className="py-4 font-serif">
                                Shift4, the first card-present payment gateway and the largest independent payment gateway in North America, is a privately held, self-funded company that does not answer to, does not profit from, and is not owned by, any card brand, issuing bank, merchant bank, payment processor, independent sales organization (ISO), or Merchant Services Provider (MSP). This independence means we can tell merchants the truth about the good, the bad, and the ugly in the payment industry without worrying about the financial interests of a parent company that stands to make money from its merchants' mistakes. We give our merchants the ability to work with any MSP or processor. Shift4 is an advocate for merchants, not MSP or ISOs – both of which, for all intents and purposes, are essentially the same thing
                                </p>
                            </div>
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650551252/cwa/assets/images/Merchants/Gateways/shift4-logo_l1otgq.png"/>
                            </div>
                        </div>  
                    </div>
                </section>
                <section className="py-10 px-5">
                    <div className="max-w-5xl mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="m-auto">
                                <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650551252/cwa/assets/images/Merchants/Gateways/usa-epay-logo_qo0nbm.gif"/>
                            </div>
                            <div className="py-10 max-w-md">
                                <h2 className="font-bold text-lg text-blue-900">USA EPAY</h2>
                                <p className="py-4 font-serif">
                                USAePay is an industry leading payment gateway that offers channel friendly payment solutions. For over 15 years, USAePay has been working with Resellers, Merchants, and Developers to provide quick, secure, credit card and check transaction processing. USAePay's payment gateway supports all major platforms in the credit card industry and works with most of the leading check platforms. USAePay is also pleased to work with many large merchant service banks in the US and abroad.
                                </p>
                            </div>
                        </div>  
                    </div>
                </section>
            </div>
            <section className="cw-bg-bluegradient">
                <div className="px-5 text-center py-5 align-center max-w-5xl mx-auto space-y-3">
                    <h3 className="text-white text-xl font-serif leading-8 ">Ready to switch to CWA Acquiring? Contact us at the number below OR Request a Quote</h3>
                    <button onClick={()=>setModal(true)}  className="transition rounded ease-in-out delay-150 border-2 border-white p-2 text-white hover:cw-text-lightblue font-semibold text-lg"> Request A Quote</button>
                </div>
            </section>
            </Layout>          
        </div>
    );
}

export default Index;